<template>
    <div id="three-container" ref="threeContainer"></div>
  </template>
  
  <script>
  import * as THREE from "three";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
  
  export default {
    name: "FloatingBalloon",
    mounted() {
      this.initThree();
    },
    methods: {
      initThree() {
        const container = this.$refs.threeContainer;
  
        // 初始化场景、相机、渲染器
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
          75,
          container.clientWidth / container.clientHeight,
          0.1,
          1000
        );
        camera.position.z = 5;
  
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(container.clientWidth, container.clientHeight);
        renderer.setClearColor(0x000000, 0); // 背景色透明
        container.appendChild(renderer.domElement);
  
        // 添加光源
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // 环境光
        scene.add(ambientLight);
        const pointLight = new THREE.PointLight(0xffffff, 1); // 点光源
        pointLight.position.set(5, 5, 5);
        scene.add(pointLight);
  
        // 创建拼色气球
        const balloonGeometry = new THREE.SphereGeometry(1, 32, 32);
  
        // 添加顶点颜色属性
        const colors = [];
        for (let i = 0; i < balloonGeometry.attributes.position.count; i++) {
          const y = balloonGeometry.attributes.position.getY(i);
          if (y > 0) {
            colors.push(1, 0, 0); // 红色（上半部分）
          } else {
            colors.push(0, 0, 1); // 蓝色（下半部分）
          }
        }
  
        balloonGeometry.setAttribute(
          "color",
          new THREE.Float32BufferAttribute(colors, 3)
        );
  
        // 使用顶点颜色的材质
        const balloonMaterial = new THREE.MeshPhongMaterial({
          vertexColors: true,
          shininess: 100,
        });
  
        const balloon = new THREE.Mesh(balloonGeometry, balloonMaterial);
        balloon.position.set(0, -5, 0); // 初始位置在页面底部
  
        // 创建气球绳子（圆柱体）
        const stringGeometry = new THREE.CylinderGeometry(0.05, 0.05, 3, 32);
        const stringMaterial = new THREE.MeshBasicMaterial({ color: 0x8b4513 });
        const string = new THREE.Mesh(stringGeometry, stringMaterial);
        string.position.set(0, -6.5, 0); // 绳子与气球对齐
  
        // 将气球和绳子组合
        const balloonGroup = new THREE.Group();
        balloonGroup.add(balloon);
        balloonGroup.add(string);
        scene.add(balloonGroup);
  
        // 添加 OrbitControls（支持鼠标交互）
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
  
        // 动画：气球从下端向上飘浮
        const animate = () => {
          requestAnimationFrame(animate);
  
          // 气球向上飘浮
          balloonGroup.position.y += 0.01; // 调整速度
          if (balloonGroup.position.y > 10) {
            // 如果气球超出视口顶部，重置到底部重新飘浮
            balloonGroup.position.y = -5;
          }
  
          // 渲染场景
          controls.update();
          renderer.render(scene, camera);
        };
        animate();
  
        // 自动调整窗口大小
        window.addEventListener("resize", () => {
          camera.aspect = container.clientWidth / container.clientHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(container.clientWidth, container.clientHeight);
        });
      },
    },
  };
  </script>
  
  <style>
  #three-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  </style>
  